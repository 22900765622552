<template>
  <div :class="`m-3 p-2 wrapper  wrapper--${bu}`">
    <h2 class="text-center">Ontbrekende kenteken</h2>
    <Loading v-if="loading" />
    <div>
      <Tabs v-if="!loading">
        <Tab
          v-for="(buyer, key) in missing_license_plates"
          :key="key"
          :title="key"
        >
          <table :class="`w-100 table--perweek table__border--${bu}`">
            <thead :class="`table__head--${bu}`">
              <tr>
                <th class="table__head--text">Foto</th>
                <th class="table__head--text">Item</th>
                <th class="table__head--text">St</th>
                <th class="table__head--text">Verkoper</th>
                <th
                  v-if="checkroles(['lev_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)"
                  class="table__head--text"
                >
                  Leverancier
                </th>
                <th class="table__head--text">Ontvangst</th>
                <th class="table__head--text">Kenteken</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, key) in buyer"
                :key="key"
                :class="`table__row--color`"
              >
                <td class="table__cell--default">
                  <img
                    :src="
                      `https://apps.kleyn.com:1919/prod/public/data/mainimage/${item.nummer}/thumb`
                    "
                    class="table__picture--maxwidth"
                  />
                </td>
                <td class="table__cell--default">
                  <ImageHover :itemnummer="item.nummer" :bu="bu" />
                </td>
                <td class="table__cell--default">{{ item.status }}</td>
                <td class="table__cell--default">{{ item.verkoper }}</td>
                <td
                  v-if="checkroles(['lev_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)"
                  class="table__cell--default"
                >
                  {{ item.leverancier }}<br />
                  {{ item.leveranciernaam }}<br />
                  {{ item.telefoon }}
                </td>
                <td class="table__cell--default">{{ item.ontvangstdatum }}</td>
                <td class="table__cell--default">
                  {{ item.kentekenomschrijving }}<br />
                  {{ item.kenteken }}
                </td>
              </tr>
            </tbody>
          </table>
        </Tab>
      </Tabs>
    </div>
  </div>
</template>

<script>
import request from "@/functions/request.js";
import Loading from "@/components/Loading.vue";
import ImageHover from "@/components/ImageHover.vue";
import Tabs from "@/components/Tabs/Tabs.vue";
import Tab from "@/components/Tabs/Tab.vue";

export default {
  props: ["bu"],
  components: { Loading, ImageHover, Tabs, Tab },
  data: () => ({
    loading: null,
    missing_license_plates: null,
  }),
  created() {
    this.getData(this.bu);
  },
  watch: {
    bu(newbu) {
      this.getData(newbu);
    },
  },
  methods: {
    getData(bu) {
      this.loading = true;
      request(`missing-license-plates/${bu}`, "GET").then(
        ({ missing_license_plates }) => {
          this.missing_license_plates = missing_license_plates;
          this.loading = false;
        }
      );
    },
  },
};
</script>
